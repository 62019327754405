import React from 'react'
import PropTypes from 'prop-types'

import './gray-callout.scss'
import SiteLink from '@components/SiteLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const GrayCallout = props => {
  return (
    <div className={`gray-callout-wrapper ${props.className}`}>
      {props.preText}
      <p className="gray-callout-text">
        {props.grayCallout.text}
      </p>
      { props.grayCallout.goTo && (
        <SiteLink to={props.grayCallout.goTo} className="gray-callout-button" tracking={props.grayCallout.tracking}>
          <p className="button-text">{props.grayCallout.buttonText}</p>
          <FontAwesomeIcon icon={faAngleRight} />
        </SiteLink>)
      }
      { props.onClick && (
        <a className="gray-callout-button" href="#" onClick={() => props.onClick()}>
          <p className="button-text">{props.grayCallout.buttonText}</p>
          <FontAwesomeIcon icon={faAngleRight} />
        </a>)
      }
    </div>
  )
}

GrayCallout.propTypes = {
  grayCallout: PropTypes.object.isRequired,
  onClick: PropTypes.func, 
  className: PropTypes.string, 
  preText: PropTypes.element
}

export default GrayCallout
